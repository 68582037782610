<template>
  <div>
    <v-combobox
      v-if="field.combo"
      v-model="field.selected"
      :items="field.answers"
      :label="field.label"
      :disabled="field.disabled"
      :placeholder="field.placeholder"
      :menu-props="{ offsetY: true }"
      outlined
      dense
      :rules="field.rules"
      :value="field.selected"
      class="selected"
    >
      <template #item="{ item }">
        <span class="labelStyle"> {{ item }}</span>
      </template>
    </v-combobox>
    <v-select
      v-else
      v-model="field.selected"
      :items="field.answers"
      :label="field.label"
      :disabled="field.disabled"
      :placeholder="field.placeholder"
      :menu-props="{ offsetY: true }"
      outlined
      dense
      :rules="field.rules"
      :value="field.selected"
      class="selected"
    >
      <template #item="{ item }">
        <span class="labelStyle"> {{ item }}</span>
      </template>
    </v-select>
  </div>
</template>
<script>
export default {
  name: "drop",
  props: ["fields"],
  data() {
    return {
      field: this.fields,
      //         rules: [
      //             value => !!value || 'Required.',
      //             value => (value && value.length >= 3) || 'Min 3 characters',
      // ],
    };
  },

  created() {
    this.$emit("fields", this.field);
  },
};
</script>

<style scoped src="../../assets/CSS/SelectField.css"></style>
